<template>
  <div>
    <BlockTitle class="mb-8 md:mb-16" :subtitle="subtitle" :title="title" />

    <p v-if="text" class="body-1 mb-24 whitespace-pre-line">
      {{ text }}
    </p>

    <CTALink :cta-link="ctaLink" :tracking="trackingData" width="fixed" />

    <YoutubeVideoModal
      v-if="ctaVideo"
      class="mt-4 md:mt-12"
      :name="videoModalName"
      :title="ctaVideo?.title"
      :youtube-id="ctaVideo?.id"
    >
      <template #trigger="{ open }">
        <CmsButtonBase
          variant="secondary"
          width="fixed"
          @click="() => openVideoModalAndSendTrackingData(open)"
        >
          {{ ctaVideo?.label }}
        </CmsButtonBase>
      </template>
    </YoutubeVideoModal>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { ContentProps } from '@backmarket/http-api/src/api-specs-content/models/content'
import BlockTitle from '@backmarket/nuxt-layer-cms/BlockTitle.vue'
import CmsButtonBase from '@backmarket/nuxt-layer-cms/CmsButtonBase.vue'
import type { ContentBlockProps as ContentBlockPropsModel } from '@backmarket/nuxt-layer-cms/models/content-block'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import CTALink from '../../shared-components/CTALink/CTALink.vue'
import YoutubeVideoModal from '../../shared-components/YoutubeVideoModal/YoutubeVideoModal.vue'

export type ContentBlockProps = ContentProps & ContentBlockPropsModel

const props = defineProps<ContentBlockProps>()

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const { trackClick } = useTracking()

const videoModalName = computed(() => `modal-video-${props.ctaVideo?.id}`)

const trackingData = computed(() => ({
  ...(props.tracking || {}),
  name: props.title,
}))

function sendTrackingData() {
  trackClick(trackingData.value)
}

function openVideoModalAndSendTrackingData(openVideoModal: () => void) {
  openVideoModal()
  sendTrackingData()
}
</script>
